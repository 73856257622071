import React, { Fragment } from "react"
import * as styles from './Service.module.scss'
import HexagonTab from "../../Hexagon/HexagonTab/HexagonTab"

import { Link } from "gatsby"
import { Icon } from '@iconify/react';


const Service = ({serviceDetails, iconType='custom', serviceIcon='eva:arrow-forward-fill', color, urlType}) => { 
    //serviceDetails: name & url
    //serviceIcon: icon src
    //color: white, colored
    let linkInfo = {to:`/${serviceDetails.url}`, state:null}
    if(urlType === 'jobPostings'){
        linkInfo = {to:`/careers-portal`, state:{category:`${serviceDetails.url}`,service: `${serviceDetails.serviceType}`}}
    }
    return(   
        <Fragment>
            <Link 
                {...linkInfo} 
                className={`${styles.service} ${styles[`${color}`]}`}>
                <HexagonTab />
                { iconType === 'custom' ?
                    <img className={styles.service__icon} src={serviceIcon} alt={`${serviceDetails.name}'s icon`} width="52" height="52" /> 
                    : <Icon icon={serviceIcon} inline={true} width="44" height='58' vAlign='center' style={{paddingBottom: '8px'}}/>
                }
               
                {serviceDetails.name}
            </Link>
        </Fragment>
        
    )
}
// state={{category:'software-solutions',service: 'professional'}}

export default Service