import React from "react"
import * as styles from './HexagonTab.module.scss'
import { Icon } from '@iconify/react';


const HexagonTab = () => {
    return(  
           <div className={`${styles.hexagonBG} hexagonTab`}>
                <Icon className={``} icon="eva:arrow-ios-forward-fill" inline={true} width={16}/>
           </div>
    )
}

export default HexagonTab