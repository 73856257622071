import * as React from "react"
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from "../components/UI/Containers/SectionContainer"
import MainTextBlock from "../components/UI/MainTextBlock/MainTextBlock"
import SectionHeader from "../components/UI/SectionHeader/SectionHeader"
import './../styles/pageSpecific/careers.scss'
import Services from './../components/UI/Services/Services'
import HalfSplit from "../components/Layouts/HalfSplit/HalfSplit"
import OurPeopleAccordian from "../components/UI/Images/OurPeopleAccordian/OurPeopleAccordian"
import VeteranProgramsSimpleList from "../components/UI/Images/VeteranProgramsList/VeteranProgramsSimpleList/VeteranProgramsSimpleList"
import ThreeBoxRow from "../components/UI/Images/ThreeBoxRow/ThreeBoxRow"
import Testimonials from "../components/UI/Testimonials/Testimonials"
import OutlineButton from "../components/UI/Buttons/OutlineButton/OutlineButton"

import CareerGreeting from './../images/careers-intro.jpg'
import TalentsIcon from './../images/icons/talents-icon.png'
import ContributeIcon from './../images/icons/contribute-icon.png'
import GoalIcon from './../images/icons/goal-icon.png'


const CareersPage = () => {
  const pageInfo = {
    pageType:'interior',
    title1: '',
    title2: 'Careers',
    breadcrumbs: [''],
    ctaButton: false,
    ctaButtonData:{
      text: '',
      url: ''
    }
}

  return (
    <GeneralPageLayout pageData={pageInfo} >
      <div className="content">
        {/* Intro Section */}
        <SectionContainer id='introCareers' color='white' type='pressedEdgeLeft'>
            <HalfSplit 
                imageObj={
                    <img src={CareerGreeting} title='greetings' alt='business people shaking hands' />
                }
                contentObj={
                    <section>
                        <SectionHeader color='navy' alignment='left' size='med' boldBreak={true}>Your Pipeline to Success <span>Starts Here</span></SectionHeader>
                        <MainTextBlock
                        buttonData={{text:'View Available Positions', url:'/careers-portal', type:'colored', icon:'uil:briefcase'}}>
                        <p>Precise Systems allows highly skilled experts to find opportunities to become a part of a close-knit team of like-minded individuals focused on our customers’ mission. Search through our current opportunities or create a Job Agent to be the first to know when your next opportunity presents itself!</p>
                        </MainTextBlock>                        
                    </section>
                }/>
        </SectionContainer>

        {/* Opportunity Section */}
        <SectionContainer id='seekOpportunity' color='gray' type='fullWidth' classes={['inlineChildren', 'threeForthsChildRight', 'alignChildrenCenter']}>
            <SectionHeader color='navy' alignment='left' size='med' trim={true} trimSpacing='large' boldBreak={true}>Are You Seeking an <span>Opportunity <span>Where...</span></span></SectionHeader>
            <ThreeBoxRow imgIconType={false}
                boxContent={{
                    first:{
                        img:TalentsIcon,
                        text:'Your talents will be recognized?'
                    },
                    second:{
                        img:ContributeIcon,
                        text:'Your daily contribution positively impacts our customers’ mission?'
                    },
                    third:{
                        img:GoalIcon,
                        text:'Your professional & personal careers goals will be embraced & supported?'
                    }
                }} 
                />
        </SectionContainer>

        {/* Services */}
        <SectionContainer id='mainServices' color='navy' type='fullWidth'>
            <SectionHeader color='white' alignment='center' size='med' trim='true' tag='Click on a field you’re interested in to check out our available positions!'>Our Featured <span>Services</span></SectionHeader>
            <Services color='white' urlType='jobPostings' />
        </SectionContainer>

        {/* Testimonials */}
        <SectionContainer id='' color='gray' type='fullWidth'>
            <SectionHeader color='navy' alignment='center' size='med' trim='true' boldBreak={true}>What People Say <span>About Working Here</span></SectionHeader>
            <Testimonials />
        </SectionContainer>

        {/* Our People */}
        <SectionContainer id='ourPeopleCareers' color='white' type='fullWidth'>
            <SectionHeader color='navy' alignment='center' size='med' trim='true' 
            tag='We help prepare professionals for new opportunities and challenges in their careers. With positions at every level, openings in multiple disciplines and locations as well as expertise in a range of programs around the globe, we create an environment where you can learn, develop, and thrive.'>
                Our <span>People</span></SectionHeader>
            <OurPeopleAccordian />
        </SectionContainer>

        {/* Veteran Programs */}
        <SectionContainer id='' color='white' type='fullWidth'>
            <SectionHeader color='navy' alignment='center' size='sm' trim='true' boldBreak={true}>Proud participants in <span>Veteran-Focused Programs</span></SectionHeader>
            <VeteranProgramsSimpleList />
        </SectionContainer>

        {/* call out */}
        <SectionContainer id='equalOpportunity' color='gray' type='fullWidth' classes={['inlineChildren', 'narrowContainer']}>
            <SectionHeader color='navy' alignment='left' size='small' trim={true} boldBreak={true}>We are Proud to be an Equal <span>Opportunity Employer</span></SectionHeader>
            <OutlineButton 
                buttonData={
                    {
                        text: 'Equal Opportunity Employer',
                        url: '/equal-opportunity-employer',
                        icon:'uil:briefcase'
                }}
                type='colored'/>
        </SectionContainer>
      </div> 
    </GeneralPageLayout>
  )
}

export default CareersPage
