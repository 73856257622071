import React from 'react'
import * as styles from './HalfSplit.module.scss'

const HalfSplit = ({
  imageObj,
  contentObj,
  imageColor = 'normal',
  imgOrientation = 'horz',
}) => {
  // imageColor: normal, navy

  return (
    <article className={`${styles.halfSplit} inlineChildren_mobile`}>
      <section
        className={`${styles.halfSplit_imageWrap} ${
          imageColor !== 'normal' ? 'navy' : ''
        } ${imgOrientation === 'vert' ? styles.imgCover : 'nope'}`}
      >
        {imageObj}
      </section>
      <section className={`${styles.halfSplit_contentWrap}`}>
        {contentObj}
      </section>
    </article>
  )
}

export default HalfSplit
