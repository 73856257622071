
import React from "react"
import { graphql, useStaticQuery  } from 'gatsby'
import * as styles from './Services.module.scss'
import ServiceList from './../../../data/services.json'
import Service from "./Service/Service"


const Services = ({color, urlType='page'}) => {
    //urlType: page, jobPostings
    //color:white, colored

    const { allFile  } = useStaticQuery(graphql`
        query {
            allFile(filter: {
                extension: {regex: "/(svg)|(png)/"}, 
                sourceInstanceName: {eq: "services"}}) 
            {
                edges {
                    node {
                        name
                        publicURL
                        }
                    }
        }}`); 
        
        const services = ServiceList.map((serviceDetail, index) => {
            let serviceIcon = allFile.edges.find(service => service.node.name === serviceDetail.icon)
            return <Service 
                    key={serviceDetail.name} 
                    color={color}
                    serviceDetails={serviceDetail} 
                    serviceIcon={serviceIcon?.node?.publicURL}
                    urlType={urlType}/>
        })
        if(urlType === 'jobPostings'){
            services.push(
                <Service 
                    key='all services' 
                    color={color} 
                    iconType='package'
                    serviceIcon='eva:arrow-forward-fill'
                    serviceDetails={{name:'View All Positions', url:'careers-portal'}}
                    urlType='page' />
            )
        }

            let key = 0
            while(services.length % 6 !== 0){
              key++
              services.push(<section key={key} className={styles.placeholderService}></section>)
            }

    return(  
        <section className={`${styles.serviceList} ${color==='white' ? styles.boxed : ''} inlineChildren_smMobile`}>
            {services ? services : ' loading...'} 
        </section>
    )
}

export default Services